.formNewRecordatorio {
    height: fit-content;
    width: 50%;
}
.title {
    font-size: 16px;
}

.linkInfo {
    display: flex;
    column-gap: 20px;
}
.input {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .formNewRecordatorio {
        height: fit-content;
        width: 100%;
    }
    
  }
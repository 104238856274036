@font-face {
  font-family: 'GothamBook';
  src: url('./assets/fonts/GothamRoundedBook_21018.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBold';
  src: url('./assets/fonts/GothamRoundedBold_21016.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamLight';
  src: url('./assets/fonts/GothamRoundedLight_21020.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('./assets/fonts/GothamRoundedMedium_21022.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --verde-seco: #a0b875;
  --verde-principal: #7bc100;
  --verde-oscuro: #6eac00;
}

input.danger {
  border: 2px solid red;
}

input.success {
  border: 2px solid var(--verde-principal);
}

select.danger {
  border: 2px solid red;
}

p.danger {
  color: red;
  font-size: 12px;
  text-align: right;
}

a {
  text-decoration: none;
  color: black;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #8e8e8e;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

body {
  margin: 0;
  font-family: GothamBook;
}
.mainContainer {
  display: flex;
}
.mainContent {
  width: 100%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
.titleContainer {
  height: 45px;
  border-radius: 4px;
  background-color: #c5f46e71;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 20px;
  margin-bottom: 48px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #474947;
  cursor: default;
}

.formNumber {
  color: red;
  font-weight: bolder;
  font-size: 16px;
}

.index {
  font-weight: bold;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: right;
  align-items: center;
  column-gap: 20px;
}
.btn span {
  color: grey;

}

.form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 300px;
  padding-top:60px;
}

.table {
  width: 87vw;
  box-shadow: inset -7px 0 9px -7px rgba(117, 114, 114, 0.4);
  overflow-x: scroll;
  margin-bottom: 100px;
  margin-top: 64px;
  height: fit-content;
  position: relative;
}
.tableSection {
  margin-top: 24px;
  width: 100%;
}
.tableSectionCheck {
  width: 75vw;
  overflow-x: scroll;
}
.input {
  width: 170px;
}

.tableRow {
  display: flex;
  column-gap: 0px;
  margin-bottom: 5vh;
  align-items: center;
}

.tableRowCheck,
.tableRowCheckTitle {
  display: flex;
  column-gap: 0px;
  align-items: center;
  height: 65px;
}
.tableRowCheckTitle {
  height: 30px;
  width: 1302px;
}
.dayTitle {
  font-weight: bold;
  display: block;
  width: 42px !important;
  font-size: 16px;
  text-align: center;
  border: 1px solid grey;
  transform: translateY(-3px);
  line-height: 35px;
}

.contMonthTable {
  display: flex;
  border: 1px solid grey;
  border-radius: 4px;
}
.optionList {
  width: 15%;
}
.optionTable,
.optionTableTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  font-weight: bold;
  text-align: center;
  border: 0.5px solid grey;
}
.optionTableTitle {
  height: 30px;
  background-color: rgb(216, 213, 213);
}

.cont-btn {
  display: flex;
  justify-content: end;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: #90C057!important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root:disabled {
  background-color: #d1d4cd!important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #90C057!important;
}


.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: grey !important;
}

   

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline  {
    border-color:  #90C057 !important;
    border-width: 2px;
  }
 
  .MuiOutlinedInput-notchedOutline ,.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color:  #90C057 !important;
    border-width: 2px;
  }



.date-pendiente {
  font-weight: lighter;
  font-size: 12px;
  color: #ffb600 ;
}
.date-normal {
  font-weight: lighter;
  font-size: 12px;
  color: grey ;
}
.date-vencido {
  font-weight: lighter;
  font-size: 12px;
  color: rgb(232, 18, 18) ;
}

.card-normal {
  border-top: 4px solid #90C057 ;
  box-shadow: 2px 4px 4px  rgb(195, 190, 190);
  padding: 20px 20px;
  padding-bottom: 15px;
  background-color: rgb(234, 233, 233);
}
.card-pendiente {
  border-top: 4px solid  #ffb600  ;
  box-shadow: 2px 4px 4px  rgb(195, 190, 190);
  padding: 20px 20px;
  padding-bottom: 15px;
  background-color: rgb(234, 233, 233);
}
.card-vencido {
  border-top: 4px solid rgb(232, 18, 18) ;
  box-shadow: 2px 4px 4px  rgb(195, 190, 190);
  padding: 20px 20px;
  padding-bottom: 15px;
  background-color: rgb(234, 233, 233);
}

.campoFileRow {
  border: 1px solid rgb(187, 184, 184);
  border-radius: 4px;
  width: 170px;
  height: 56px;
  padding: 7px;

}
.linkFileRow {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #6eac00;
}

.linkFileRowNoArcchivo {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #6eac00;
}

@media screen and (max-width: 525px){
  .form {
    padding-bottom: 100px;
  }
  .titleContainer {
    height: 55px
  }
}



.personal, .personalText {
    display: flex;
    column-gap: 16px;
    margin-bottom: 5vh;
}

.subtituloTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(229, 224, 224, 0.772);
    border-radius: 2px;
    padding-top: 4px;
    width: 598px;   
}

.subtituloTable2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(229, 224, 224, 0.772);
    border-radius: 2px;
    padding-top: 4px;
    width: 338px;  
}

.contTitTabla {
    display: flex;
    width: 568px ;
    margin-left: 40px; 
}
.contTitTabla2 {
    display: flex;
    width: 338px ;
    margin-left: 40px; 
}

.subtitleCont {
    border-bottom: 2px solid rgb(37, 35, 35);
    margin-bottom: 24px;
}

.subtitle {
    font-weight: bold;
    font-size: 15px;
    color: rgb(37, 35, 35);
}

span {
    font-size: 12px;
}

.limites {
    display: flex;
    gap: 30px;
    max-height: 410px;
}
.limitesImg {
    width: 100%;
    min-width: 700px;
    min-height: 250px;
}

.selectIns {
    width: 200px;
}
@media screen and (max-width: 525px){
    .personal, .personalText {
       flex-direction: column;
       row-gap: 16px;
    }
    .selectIns {
        width: 100% ;
    }
  } 
.notificationGreen , .notificationRed {
    position: absolute;
    right: 40px;
    top: 150px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 400px;
    border-radius: 30px;
    color: white;
    z-index: 100;
}
.notificationGreen {
    background-color: #a0b875;
}
.notificationRed {
    background-color: #f39d5e;
}
.textAlert{
    color: white;
    text-align: center;
    display: block;
    font-weight: lighter;
    margin-top: 8px;
}
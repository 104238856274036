
.personal, .personalText {
    display: flex;
    column-gap: 16px;
    margin-bottom: 5vh;
}
.personalText {
    flex-direction: column;
}

.personalText {
    width: 61vw;
    min-width: 300px;
}
.label{
    font-size: 12px;
    color: grey;
}
.subtitleCont {
    border-bottom: 2px solid rgb(37, 35, 35);
    margin-bottom: 24px;
}

.subtitle {
    font-weight: bold;
    font-size: 15px;
    color: rgb(37, 35, 35);
}


.responsableCont {
    margin-bottom: 80px;
}

.listContainer {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    margin-bottom: 32px;
    
}

.listItem {
    width: 40%;
    min-width: 300px;
}

.boldLegend {
    text-align: center;
    margin-bottom: 80px;
    font-weight: bold;
}

.fileInput {
    border: 1.5px solid rgba(173, 172, 172, 0.741);
padding-top: 1rem;
padding-bottom: 0.6rem;
padding-left: 0.5rem;
padding-right: 0.5rem;
border-radius: 0.3rem;
max-width: 400px;
min-width: 300px;
max-height: 400px;

}
.selectTipo {
    width: 20%;
}
.campo {
    width: 50%;
}
@media screen and (max-width: 525px){
    .personal {
        flex-direction: column;
        row-gap: 16px;
        margin-bottom: 16px;
    }
    .selectTipo {
        width:100%;
    }
    .campo {
        width: 100%;
    }
    .personalText {
        width: 100%;
        min-width: unset;
        flex-direction: column;
        column-gap: 16px;
    }
    .listContainer {
        width: 100%;
        /* display: flex;
        flex-direction: column; */
        /* padding-left: 24px;
        margin-bottom: 32px; */
        
    }
    /* 
    
    .descipcionArea {
        width: 100%;
    } */
  } 
.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 200px;
  }
  
  .wrapper {
    width: 93%;
  }
  
  .cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
  }
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 59%;
  max-width: 600px;
  border-radius: 20px;
  text-align: center;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  width: 100%;
  height: 80px;
  margin-top: 18px;
  margin-bottom: 38px;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  padding: 10px 0 0 10px;
  outline: none;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 31px 0 14px 0;
}

.textMotivo {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: rgb(41, 40, 40);
  font-style: italic;
}

.text {
  font-weight: 300;
  font-size: 17px;
  line-height: 22px;
  color: black;
  margin-top: 20px;
}
.contBtn {
  display: flex;
  justify-content: end;
  gap: 20px;
}

.contBtn button {
  background: #7bc100;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  color: white;
  width: 7rem;
}

#btnDenegar {
  border: 1px solid #7bc100;
  background-color: white;
  color: #7bc100;
}

#btnCancelar {
  border: none;
  background-color: white;
  color: #7bc100;
}

@media screen and (max-width: 576px) {
  .modal {
    width: 90%;
  }
}

.cardContainer {
  /* width: 188px; */
  height: 188px;
  background: #e7e7e7;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.cardContainer:hover {
  background: #7bc100;
  color: white;
}

.cardWrapper {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.titleCard {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}

.asterisco{
  font-weight: bolder;
  color: white;
  font-size: 30px;
  position: absolute;
  top: 5px;
  right: 15px;
}
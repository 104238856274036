.personal, .personalText {
    display: flex;
    column-gap: 16px;
    margin-bottom: 5vh;
}

.personalText {
    width: 61vw;
    min-width: 300px;
}
.descripcion {
   
}
.aclaracion{
    font-size: 14px;
    color: rgb(46, 45, 45);
}
.subtitleCont {
    border-bottom: 2px solid rgb(37, 35, 35);
    margin-bottom: 24px;
}

.subtitle {
    font-weight: bold;
    font-size: 15px;
    color: rgb(37, 35, 35);
}

.tableLabel {
    font-weight: 500;
    display: block;
    width: 250px;
}

.responsableCont {
    margin-bottom: 80px;
}
.previewImage{
    width: 85px;
}

.border {
    border: 0.5px solid rgba(220, 220, 220, 0.998);
    border-color: rgb(189, 188, 188);
    border-radius: 5px;
    color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    padding-inline: 15px;
}
.descipcionArea {
    width: 450px;
}
@media screen and (max-width: 525px){
    .personal {
        flex-direction: column;
        row-gap: 16px;
        margin-bottom: 16px;
    }
    .personalText {
        width: 100%;
        min-width: unset;
        flex-direction: column;
        column-gap: 16px;
    }
    .selectTipo {
        width: 90vw !important;
    }
    .descipcionArea {
        width: 100%;
    }
  } 
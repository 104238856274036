.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
}

.wrapper {
  width: 93%;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 25px;
}

.orderContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 34px;
  gap: 10px;
  align-items: center;
}

#select {
  box-sizing: border-box;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 15px;
  outline: none;
}

.spanOrder {
  font-weight: 325;
  font-size: 15px;
  line-height: 18px;
}
.tituloRecord{
  font-weight: bold;
  font-size: 21px;
  margin-bottom: -10px;
  margin-top: 20px;
  display: block;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid grey;
    cursor: default;
}
.headerSup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
}

.title {
    font-weight: bold;
    font-size: 14px;
}

.frecuencia {
    font-weight: lighter;
    font-size: 14px;
    color: grey;
}
.description {
    font-size: 13px;
    margin-top: 10px;
    font-weight: lighter;
    margin-bottom: 7px;
    cursor: default;
}

.link {
    color: blue;
    font-size: 13px;
}
.fecha{
    display: flex;
    column-gap: 5px;
}
.selectContainer {
    display: flex;
    flex-direction: column;
}

.selectContainer label {
    font-size: 12px;
    color: grey;
}
.selectContainer select {
    font-size: 12px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
}
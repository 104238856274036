.containerBody {
    display: flex;
    justify-content: space-between;
    column-gap: 5%;
    padding-bottom: 130px;
    width: 95%;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
}
.tituloRecord{
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 40px;
    display: block;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .containerBody {
        flex-direction: column;
        justify-content: space-between;
        column-gap: unset;
        row-gap: 30px;
        padding-bottom: 130px;
    }
    .tituloRecord{
        font-size: 18px;
        margin-bottom: 40px;
    }
    
  }
  
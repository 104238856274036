.eventos {
    height: fit-content;
    width: 50%;
}

.title {
    font-size: 16px;
}
.containerItems {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 15px;
    height: fit-content;
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 12px;
    padding-bottom: 20px;

}
.containerItems::-webkit-scrollbar {
    width: 8px;
  }
  
  .containerItems::-webkit-scrollbar-thumb {
    background-color: rgb(129, 128, 128);
    border-radius: 4px;
  }
  
  .containerItems::-webkit-scrollbar-track {
    background-color: #cbd5e0;
  }
  

.textPlaceholder{
    font-weight: lighter;
    color: grey;
    text-align: center;
    margin-top: 50px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

.selectContainer label {
  font-size: 12px;
  color: grey;
}
.selectContainer select {
  font-size: 12px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid grey;
}

.titleRdos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 10px;
}
.filters {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
}
select option {
  height: 10px; /* Establece la altura de las opciones */
}
@media screen and (max-width: 768px) {
  .eventos {
      height: fit-content;
      width: 100%;
  }
  .containerItems {
    height: fit-content;
    max-height: 90vh;

  }
  .filters {
    flex-direction: column-reverse;
    column-gap: unset;
    row-gap: 20px;
    margin-top: 20px;
  }
}

.personal, .personalText {
display: flex;
column-gap: 16px;
margin-bottom: 5vh;
}

.personalText {
    width: 61vw;
    min-width: 300px;
}
.subtitleCont {
    border-bottom: 2px solid rgb(37, 35, 35);
    margin-bottom: 24px;
}

.subtitle {
    font-weight: bold;
    font-size: 15px;
    color: rgb(37, 35, 35);
}

.border {
    border: rgb(184, 172, 172) 1px solid;
    border-radius: 5px;
    justify-content: center;
    margin-top: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 525px){
    .personal {
        flex-direction: column;
        row-gap: 16px;
    }
    /* .inputRow {
       flex-direction: column;
       align-items: unset;
       margin-bottom: 25px;
    }
    .inputRow p {
        width: 100%;
     } */
  }
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18vh;
}

.wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 34px;
}

.inputContainer {
  position: relative;
}

.search {
  box-sizing: border-box;
  max-width: 400px;
  min-width: 300px;
  width: 100%;
  height: 40px;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  padding: 0 10px 0 42px;
}

.rightContainer {
  font-size: 24px;
  font-weight: 325;
  line-height: 29px;
  letter-spacing: 0em;
}

@media screen and (max-width: 768px) {
  .container {
    margin-bottom: 23px;
  }

  .wrapper {
    flex-direction: column;
    gap: 30px;
    padding-top: 46px;
    margin-bottom: 23px;
  }

  .rightContainer {
    align-self: flex-start;
  }
}

@media screen and (max-width: 475px) {
  .leftContainer {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
}


.container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 180px;
}

.wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftWrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
}

.nivel {
  font-size: 20px;
}

.titleLogin {
  font-size: 24px;
  color: #636363;
  cursor: default;
  margin-bottom: 40px;
}

.formLogin {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inputLogin {
  border-radius: 10px;
  max-width: 400px;
  min-width: 300px;
  width: 42vw;
  border: 1px solid #c3c3c3;
  padding: 5px 20px;
}

.inputContraseñaCont {
  position: relative;
}

.rightWrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 500px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 100px;
}

.btn {
  padding: 5px 55px;
  border-radius: 10px;
  color: white;
  border: none;
}

.btn:enabled:hover {
  background-color: var(--verde-oscuro) !important;
}

label {
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    flex-direction: column-reverse;
  }
  .leftWrapper {
    width: 100%;
  }

  .logo {
    width: 85%;
  }
}

@media screen and (max-width: 576px) {
  .logo {
    width: 100%;
  }
}

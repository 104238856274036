
.personal, .personalText, .personalRight, .firma {
    display: flex;
    column-gap: 16px;
    margin-bottom: 5vh;
}

.personalRight {
    justify-content: end;
}

.personalText {
    width: 61vw;
    min-width: 300px;
}

.subtitleCont {
    border-bottom: 2px solid rgb(37, 35, 35);
    margin-bottom: 24px;
}

.subtitle {
    font-weight: bold;
    font-size: 15px;
    color: rgb(37, 35, 35);
}

.listTitle {
    display: flex;
    align-items: baseline;
}


.listTitle > p{
    margin-left: 20px;
    font-size: 12px;
}

.listContainer {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    margin-bottom: 32px;
}

.listItem {
    width: 40%;
    min-width: 300px;
}
.border {
    border: rgb(184, 172, 172) 1px solid;
    border-radius: 5px;
    justify-content: center;
    margin-top: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.matDidacticoSection {
    display: flex;
    gap: 10%;
}

.firma {
    margin-bottom: 80px;
    border: rgb(184, 172, 172) 1px solid;
    border-radius: 5px;
    justify-content: center;
    margin-top: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

  
  .largeSelectInput {
    padding-left: 6rem;
  }
  .file {
    border-radius: 5px;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: center;
        cursor: pointer;
}


@media screen and (max-width: 525px){
    .matDidacticoSection {
        flex-direction: column;
    }
  } 
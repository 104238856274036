.container {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-bottom: 130px;
}

.wrapper {
  width: 93%;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 25px;
}

.nivelContainer {
  padding-top: 26px;
  padding-bottom: 29px;
}

.titleNivel {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
